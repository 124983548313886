/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Loader({ fullScreen }) {
  const { t } = useTranslation();
  const loadingText = t('loading', { returnObjects: true });
  const { language } = useI18next();
  const locale = language === undefined ? 'fr' : language;

  /* ******************************** RENDERING ******************************* */

  return (
    <div
      className={`loader-container ${fullScreen ? 'full-screen' : 'default'}`}
    >
      <svg
        width="112"
        height="163"
        viewBox="0 0 112 163"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="56" cy="56" r="56" fill="#E7343F" />
        <circle className="dot dot-1" cx="29" cy="56" r="7" fill="white" />
        <circle className="dot dot-2" cx="56" cy="56" r="7" fill="white" />
        <circle className="dot dot-3" cx="83" cy="56" r="7" fill="white" />
        <text y="150" x={`${locale === 'fr' ? '-20' : 0}`} className="text">
          {loadingText}
        </text>
        <text
          className="text-dot text-dot-1"
          y="150"
          x={`${locale === 'fr' ? '125' : '93'}`}
        >
          .
        </text>
        <text
          className="text-dot text-dot-2"
          y="150"
          x={`${locale === 'fr' ? '130' : '98'}`}
        >
          .
        </text>
        <text
          className="text-dot text-dot-3"
          y="150"
          x={`${locale === 'fr' ? '135' : '103'}`}
        >
          .
        </text>
      </svg>
    </div>
  );
}

Loader.propTypes = {
  fullScreen: PropTypes.bool,
};

Loader.defaultProps = {
  fullScreen: false,
};

export default Loader;
