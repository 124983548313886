/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// styles
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                    Component                                    */
/* -------------------------------------------------------------------------- */

function Spinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner" />
    </div>
  );
}

export default Spinner;
