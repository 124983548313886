/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Style
import './index.scss';
// Assets
import EyesPassword from '../../../images/shared-images/eye-white 1.png';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function InputField({ name, type, placeholder, required, label, defValue }) {
  /* ******************************** CONSTANTS ******************************* */
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // State
  const [showPassword, setShowPassword] = useState(false);

  // Other hooks like reux or localization ...

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */

  return (
    <>
      <input
        name={name}
        className="input-field"
        type={showPassword ? 'text' : type}
        placeholder={placeholder}
        defaultValue={defValue}
        required
      />
      {type === 'password' ? (
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="button-pwd"
        >
          <img src={EyesPassword} alt="" />
        </button>
      ) : null}
      <span className={`input__label${required ? '-required' : ''}`}>
        {label}
      </span>
    </>
  );
}

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defValue: PropTypes.string,
};

InputField.defaultProps = {
  required: false,
  placeholder: '',
  defValue: '',
};

export default InputField;
