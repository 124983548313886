/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// UI lib comoponents
import { Container, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
import Image from '../../Image';

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// Assets
import Select from '../../../images/Layout/select.svg';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function SelectMenu({ visitedPage }) {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */
  const { t } = useTranslation();
  const { buttons } = t('selectMenu', { returnObjects: true });
  const { logoutText } = t('Profile', { returnObjects: true });

  // state
  const [showDropMenu, setShowDropMenu] = useState(false);
  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ***************************** RENDER HELPERS ***************************** */

  function DropDownMenu() {
    return (
      <div className="select-menu-dropdown">
        {buttons.map(({ id, buttonText, url, img }) => (
          <Link to={url} key={id} className="select-menu-dropdown-link">
            <Image src={img} alt="button-Icon" />
            {buttonText}
          </Link>
        ))}
        <Link to="/" className="select-menu-dropdown-link logout">
          {logoutText}
        </Link>
      </div>
    );
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="select-menu">
      <Row className="select-menu-link" align="center" justify="center">
        <Image src="profilegray.png" alt="img" />
        <h1 className="select-menu-title">{visitedPage}</h1>
        <button
          type="button"
          className="select-menu-button"
          onClick={() => setShowDropMenu(!showDropMenu)}
        >
          <img src={Select} alt="select" />
        </button>
      </Row>
      {showDropMenu ? <DropDownMenu /> : null}
    </Container>
  );
}

SelectMenu.propTypes = {
  visitedPage: PropTypes.string.isRequired,
};

SelectMenu.defaultProps = {};

export default SelectMenu;
