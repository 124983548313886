/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, navigate } from 'gatsby';
// UI lib comoponents
import { Container } from 'react-grid-system';
// Local UI components
import Image from '../../Image';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
import { logout } from '../../Services/auth';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function SideMenu({ visitedPage, pageName }) {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // State

  // localization ...
  const { t } = useTranslation();
  const { buttons } = t('sideMenu', { returnObjects: true });
  const { logoutText } = t('Profile', { returnObjects: true });
  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ***************************** RENDER HELPERS ***************************** */

  const renderButtons = buttons.map(
    ({ id, buttonText, url, imgOnFocus, img }) => {
      if (url === visitedPage) {
        return (
          <Link to={url} key={id} className="visited">
            <Image src={imgOnFocus} alt="button-icon" />
            {buttonText}
          </Link>
        );
      }
      return (
        <Link to={url} key={id}>
          <Image src={img} alt="button-icon" />
          {buttonText}
        </Link>
      );
    },
  );

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="side-menu" fluid>
      <div>
        <h1>{pageName}</h1>
      </div>
      <div className="side-menu-links">{renderButtons}</div>
      <button
        className="deconnexion"
        type="button"
        onClick={() => logout(() => navigate('/'))}
      >
        <Image src="deconnexion.png" />
        {logoutText}
      </button>
    </Container>
  );
}

SideMenu.propTypes = {
  visitedPage: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
};

SideMenu.defaultProps = {};

export default SideMenu;
