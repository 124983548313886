/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// Lib UI components
import { Container, Row, Col, Visible } from 'react-grid-system';

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import SideMenu from '../shared/UIKit/SideMenu';
import ProfileForm from '../page-components/Profile/ProfileForm';
import SelectMenu from '../shared/UIKit/SelectMenu';

// Style
import '../page-styles/Profile.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Profile({ location }) {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();
  const { title } = t('Profile', { returnObjects: true });

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Profile" />
      <Container className="profile" fluid>
        <Visible xxl xl lg>
          <Row>
            <Col xl={3} lg={3} md={3}>
              <SideMenu pageName={title} visitedPage={location.pathname} />
            </Col>
            <Col>
              <ProfileForm />
            </Col>
          </Row>
        </Visible>
        <Visible md sm xs>
          <SelectMenu visitedPage={title} />
          <ProfileForm />
        </Visible>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["Profile", "Common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
Profile.propTypes = {
  location: PropTypes.string,
};
Profile.defaultProps = {
  location: {},
};

export default Profile;
