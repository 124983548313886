/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

// Style
import './index.scss';
import Spinner from '../Spinner';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function FormButton({ text, navigationlink, loading, onSubmit }) {
  /* ******************************** RENDERING ******************************* */
  if (navigationlink === null) {
    return (
      <button type="submit" className="form-button" onClick={onSubmit}>
        {loading ? <Spinner /> : text}
      </button>
    );
  }
  return (
    <button
      type="button"
      className="form-button"
      onClick={() => navigate(navigationlink)}
    >
      {loading ? <Spinner /> : text}
    </button>
  );
}

FormButton.propTypes = {
  text: PropTypes.string.isRequired,
  navigationlink: PropTypes.string,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};
FormButton.defaultProps = {
  navigationlink: null,
  loading: false,
  onSubmit: () => {},
};

export default FormButton;
