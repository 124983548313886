/* eslint-disable react/no-unescaped-entities */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState, useEffect } from 'react';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import InputField from '../../../shared/UIKit/InputField/index';
import FormButton from '../../../shared/UIKit/FormButton';
import Image from '../../../shared/Image';
import Loader from '../../../shared/UIKit/Loader';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
import { getUser, updateUser } from '../../../shared/Services/auth';
import { baseUploads } from '../../../shared/APIs';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function ProfileForm() {
  /* ********************************** HOOKS ********************************* */

  // State
  const [userDetails] = useState(getUser());
  const defaultImageProfile = `${baseUploads}${userDetails?.profilePicture}`;
  const [imageProfile, setImageProfile] = React.useState(defaultImageProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [hasImageChanged, setHasImageChanged] = useState(false);

  /* ***************************** LOCAL VARIABLES **************************** */
  /* ******************************** CALLBACKS ******************************* */
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageProfile(URL.createObjectURL(event.target.files[0]));
      setHasImageChanged(true);
    }
  };

  // Handle form new data
  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const fieldValues = Object.fromEntries(formData.entries());

    updateUser(
      userDetails.token,
      fieldValues,
      setIsUpdated,
      hasImageChanged,
      setIsLoading,
    );

    setHasImageChanged(false);

    return true;
  }

  useEffect(() => {
    if (isUpdated === true) {
      setTimeout(() => setIsUpdated(false), 5000);
    }
  }, [isUpdated]);
  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */

  return (
    <>
      {isLoading ? <Loader fullScreen /> : null}

      <form onSubmit={handleSubmit}>
        <Container className="profile-form">
          <Row align="center" justify="center">
            <Col xxl={8} xl={8} lg={8} style={{ marginTop: '2rem' }}>
              <div className="profile-form-image-container">
                {userDetails?.profilePicture === undefined ? (
                  <Image src="fakeperson.png" qlt="person" />
                ) : (
                  <img
                    src={imageProfile}
                    alt="person"
                    className="profile-photo"
                  />
                )}
                <div className="person-container">
                  <h2 className="mail">{userDetails.fullName}</h2>
                  <div className="buttons-container">
                    <label className="label-img" htmlFor="actual-btn">
                      Modifier la photo
                      <input
                        type="file"
                        name="profilePicture"
                        id="actual-btn"
                        hidden
                        onChange={onImageChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row align="center" justify="center" style={{ marginTop: '5rem' }}>
            <Col xxl={4} xl={4} lg={4} className="input">
              <InputField
                name="fullName"
                type="text"
                placeholder="Nom et prénom"
                label="Nom et prénom"
                defValue={userDetails.fullName}
              />
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              <InputField
                name="function"
                type="text"
                placeholder="Fonction"
                label="Fonction"
                defValue={userDetails.function}
              />
            </Col>
          </Row>
          <Row align="center" justify="center" style={{ marginTop: '2rem' }}>
            <Col xxl={4} xl={4} lg={4} className="input">
              <InputField
                name="companyName"
                type="text"
                label="Nom de l'entreprise"
                defValue={userDetails.companyName}
              />
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              <InputField
                name="phoneNumber"
                type="text"
                label="Numéro de téléphone"
                defValue={userDetails.phoneNumber}
              />
            </Col>
          </Row>
          <Row align="center" justify="center" style={{ marginTop: '2rem' }}>
            <Col xxl={4} xl={4} lg={4} className="input">
              <InputField
                name="location"
                type="text"
                label="Emplacement"
                defValue={userDetails.location}
              />
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              <InputField
                name="activityArea"
                type="text"
                placeholder="Secteur d'activité"
                label="Secteur d'activité"
                defValue={userDetails.activityArea}
              />
            </Col>
          </Row>
          <Row align="center" justify="center" style={{ marginTop: '2rem' }}>
            <Col xxl={8} xl={8} lg={8}>
              <textarea
                name="companyDescription"
                defaultValue={userDetails.companyDescription}
              />
              <span>description de l'entreprise</span>
            </Col>
          </Row>
          <Row align="center" justify="between" style={{ marginTop: '2rem' }}>
            <Col xxl={4} xl={4} lg={4}>
              <div className="popup-container">
                {isUpdated === true ? (
                  <p className="popup"> l`enregistrement a effectué</p>
                ) : null}
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              <FormButton text="Enregistrer" />
            </Col>
          </Row>
        </Container>
      </form>
    </>
  );
}

ProfileForm.propTypes = {};

ProfileForm.defaultProps = {};

export default ProfileForm;
